
import React from 'react';


import {ApiGet} from './Api';

//import { Container, Row, Col } from 'bootstrap-4-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import {dateConvert, penceToPounds, RAGhostingPercent, RAGhostingDisk, HostingMergeToData, pad, timedateConvert, daysOld, byteToMegabyte, secondstotime} from './Library';







const RegistrarFirstStageContent = (obj) => {
    //console.log('object',obj)

    let container = {}

    container.Item = obj.index;
    container.domain = obj.domain
//            container.domain = '<a href="https://www.' + obj.domain + '">' + obj.domain + '</a>';
    container.Url = 'https://www.'+ obj.domain;


    container.expiration_date = "";
    container.name_servers = "";
    container.registrar = "";
    container.registrar_url = "";

  
    //container.TDcomments = "";
    

    if ((obj.hasOwnProperty('information')) && (obj.information.hasOwnProperty('registration'))) {
        container = {...container, ...obj.information.registration}


        //console.log('XXXXXXXXXX', obj.information.registration, typeof container.name_servers);

        container.name_servers = container.name_servers.toString().split(',').join('\n');       // split array values over new rows
        container.registrar_url = container.registrar_url.toString().split(',').join('\n');     // split array values over new rows
        container.registrar = container.registrar.split(' ', 1)[0].split(',', 1)[0];            // only want first word
        const timeStamp =  container.expiration_date                                            
        container.expiration_date = dateConvert(timeStamp);                                     // only want date part of string and in preffered format
        
        const daysLeft = -daysOld(timeStamp);
        container.TDexpiration_date     = "Blue"; 
        if        (daysLeft <= 7) {
            container.TDexpiration_date = "Red"; 
        } else if (daysLeft <= 31) {
            container.TDexpiration_date = "Amber"; 
        } else if (daysLeft <= 365) {
            container.TDexpiration_date = "Green"; 
        }

    }
    return (container);
}


// const Status = ({ data }) => {
class RegistrarInformation extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {expand: [false] };
        
        this.expand = this.expand.bind(this);
    }


    expand = (index) => {
        const newState = this.state.expand;

        if (newState[index]) {
            // here if index is currently expanded
            newState[index] = false; 
        } else {
            // here if index item is currently not expanded
            newState.forEach((element, index) => newState[index] = false); 
            newState[index] = true; 
        } 

        this.setState({expand: newState});
    }

    //const function timedateConvert(timestamp) {

    //console.log(data);
    render() {

        if (this.props.data.length === 0) { // if no data then do nothing
            return null
        }
        const expand = this.state.expand;

        const toIndex = 0;

        const toState = expand[toIndex];

        const toCols = 2; //toState ? 8 : 2;
        //console.log(this.props.data);

        const firststage = this.props.data.map((obj) => {
            return RegistrarFirstStageContent(obj);
        })

       console.log('firststage', firststage);
       //{firststage.filter(({status}) => status === 'active').reduce((a, b) => +a.totalMonth + +b.totalMonth)}

       //const filter =  firststage.filter(({status}) => status === 'active');
       //const map = filter.map(i => i.totalMonth);
       //const reduce =  map.reduce((a, b) => +a + +b);

        //ole.log('filter------------------', filter, map, reduce, firststage.filter(({status}) => status === 'active').map(i => i.totalMonth).reduce((a, b) => +a + +b)); 
       
        const listing = firststage.map((obj) => (
            <tr className="HostingStatusRow" key={obj.domain}>
                <td className="number">{obj.Item}</td>
                <td className="domain"><a href={obj.Url}>{obj.domain}</a></td>

                <td className={obj.TDexpiration_date}>{obj.expiration_date}</td>
                <td>{obj.name_servers}</td>
                <td>{obj.registrar}</td>
                <td>{obj.registrar_url}</td>   

            </tr>
        ))
    

        const output = [
            <table className="HostingStatusTable" key="HostingStatusTable">
                <tbody>
                    <tr>
                        <th className="HostingStatusTitle">Item</th>
                        <th className="HostingStatusTitle">Domain</th>
                        <th className="HostingStatusTitle">Expiration Date</th>
                        <th className="HostingStatusTitle">Name Servers</th>
                        <th className="HostingStatusTitle">Registrar</th>
                        <th className="HostingStatusTitle">Registrar URL</th>
                    </tr>
                    {listing}

                </tbody>
            </table>
        ]

 


        return (
            <>
                {output}
            </>
        )
    }

}



class Epdregistrar extends React.Component {
    constructor() {
        super();
        //this.state = { data: [] };
    }




/*
    componentDidMount() {
        ApiGet('domain') 
        .then(res => {
            const domains = res.Items;
            ApiGet('hosting') 
            .then(res => {
                const newdata = HostingMergeToData(res.listaccts.data.acct, res.showbw.data.acct, domains);
                this.setState({ data: newdata });
            });
        });
    }
    
*/ 
    render() { 

        console.log('Epdhosting', this.props.state.data);

        const textStyle = {
            textAlign: "center"
        };
    
        if (this.props.state.data.length === 0) {

            return (

                <Container fluid="lg">
                    <Row>
                        <Col>
                            <h1 style={textStyle}>Registrar</h1>
                            <div style={textStyle}><Spinner  animation="border" /></div>
                        </Col>
                    </Row>
                </Container>
            );

        }
        else {

            return (
                <Container fluid="lg">
                    <Row>
                        <Col>
                             <h1 style={textStyle}>Registrar</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <RegistrarInformation data={this.props.state.data} />
                        </Col>
                    </Row>
                </Container>

            );
        }
    }
}

export {Epdregistrar, RegistrarFirstStageContent };


