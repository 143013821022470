import React, { useEffect } from 'react';
//import logo from './logo.svg';
import './App.css';
//import * as ReactBootstrap from 'react-bootstrap';
import { Auth } from 'aws-amplify';
//import awsconfig from './aws-exports';
//import { AmplifyAuthenticator, withAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
import Authentication from './Authentication.js';
//import AuthStateApp from './AuthState.js';
//import { AuthState } from '@aws-amplify/ui-components';



import { Hub } from 'aws-amplify';
import Logo from './epdlogo_lg.jpg'
import { Button, Container, Row, Col, Navbar, Nav, ButtonToolbar } from 'react-bootstrap';

import { Route, Switch, Redirect, withRouter, useLocation, useHistory} from 'react-router-dom';

import Home from './Home';
import Epdanalytics from './Epdanalytics';
import Epddetails from './Epddetails';
import {Epduptime} from './Epduptime';
import {Epdbackupstatus} from './Epdbackupstatus';
import {Epdsites} from './Epdsites';
import Epdsummary from './Epdsummary';
import {Epdhosting} from './Epdhosting';
import {Epdinformation} from './Epdinformation';
import {Epdregistrar} from './Epdregistrar';
import NavConfirm from './NavConfirm';
import Refresh from './Refresh';

import {contentFsm, EpdFullApiLoad, EpdSmartApiLoad} from './Library'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

const SetState = (props) => {

    useEffect(() => {

        console.log('USEFFECT-----------', props);
        (props.state.data.length === 0) && EpdFullApiLoad(props.setState);

    },[props]
    )

    return null;

}

class xxxSetState extends React.Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        console.log('MOUNT------------------------------------------------------------');
        (this.props.state.data.length === 0) && EpdFullApiLoad(this.props.setState);
    }


 
    render() {return(null)}

       
}
//     <SetState state={this.state} setState={this.setState}/>


class Action extends React.Component {
    constructor(props) {                 
        super(props);
    }


    componentDidMount() {
        console.log('StateChange Mounted')
        this.props.action();
    } 


    render() {

        console.log('StateChange');

        return null;
    }
}
        

class Content extends React.Component {
    constructor(props) {                 
        super(props);
        //this.state = { refresh: 0, authy: contentFsm.UNDEFINED };
        this.state = { authy: contentFsm.UNDEFINED, user: [], data: [], lastUpdate: null, err: "", loading: false, mounted: false };
        //this.path = '';

        //ReactGA.initialize(googleAnalytics); 
       // console.log('---------------AnalyticsA', googleAnalytics, this.props.history.location.pathname);
       // ReactGA.send({ hitType: "pageview", page:  this.props.history.location.pathname, appVersion: environment });        

        this.lastLocation = this.props.history.location;
        //this.lastLocation.pathname = null;
        this.handleSelect = this.handleSelect.bind(this);
        this.setState = this.setState.bind(this);
        this.signin = this.signin.bind(this);
        this.signout = this.signout.bind(this);
        this.cancel = this.cancel.bind(this);


      }

        //state = { authy: contentFsm.UNDEFINED };

        componentDidMount() {

            Hub.listen('auth', (data) => {
                const { payload } = data;
                this.onAuthEvent(payload);
                //if (data.payload.data  != null)
                  //  console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
            });
            this.setState({mounted: true});
        } 


    onAuthEvent(payload) {
        //this.setState({ count: this.state.count + 1 });
        //this.setState({ aState: 'test' });
        console.log('auth event============', payload.event);
        //this.setState({ count: this.state.count + 1 });
        this.setauthstate(payload.event);
    }



    signin = () => {
        //this.setState({ count: this.state.count + 1 });
        //console.log('SI button');
        if (this.state.authy !== contentFsm.REQUEST_SIGN_IN) {
            this.setState({ authy: contentFsm.REQUEST_SIGN_IN });
        }
    }

    signout = () => {
        //this.setState({ count: this.state.count + 1 });
        //console.log('SO button')
        if (this.state.authy !== contentFsm.REQUEST_SIGN_OUT) {
            this.setState({ authy: contentFsm.REQUEST_SIGN_OUT });
        }
    }

    cancel = () => {
        //this.setState({ count: this.state.count + 1 });
        //console.log('SO button')
        //if (this.state.authy !== contentFsm.REQUEST_SIGN_OUT) {
            this.setState({ authy: contentFsm.SIGNED_OUT});
        //}
    }

//const contentFsm = { UNDEFINED: 0, REQUEST_SIGN_OUT: 1, SIGNED_OUT: 10, REQUEST_SIGN_IN: 11, SIGNED_IN: 100, REQUEST_CODE: 200 };

    setauthstate = (event) => {
        console.log('set Auth State+++++++++++++', Auth, event, this.state.authy); 
        Auth.currentAuthenticatedUser() // if we have a user then we are logged on
            .then( 
                user => {
                    if (this.state.authy !== contentFsm.SIGNED_IN) {
                        // set to logged in

                        console.log('user: ', user);
                        this.setState({ authy: contentFsm.SIGNED_IN });
                        //(this.props.state.data.length === 0) && EpdFullApiLoad(this.props.setState);

                        switch (this.props.history.location.pathname) {
                            case "/home":
                            case "/":
                                // no data loading as this is a new invocation on the home page
                                break;

                            default:
                                EpdSmartApiLoad(this.state, this.setState, "maximum");
                        }
                    }
            })
            .catch(
                err => {
                    switch (this.state.authy) {
                        case contentFsm.SIGNED_OUT:         break;
                        case contentFsm.REQUEST_SIGN_IN: 
                                                            if (event === 'forgotPassword') {
                                                                this.setState({ authy: contentFsm.REQUEST_SIGN_IN });
                                                            }
                                                            break;
                        default:
                                                            console.log('err: ', err);
                                                            this.setState({ authy: contentFsm.SIGNED_OUT });
                                                            EpdSmartApiLoad(this.state, this.setState, "unload"); // UNLOAD
                    }
                    /*
                    if      (this.state.authy === contentFsm.SIGNED_OUT) {
                        if (event === 'forgotPassword') {
                            this.setState({ authy: contentFsm.REQUEST_CODE });
                        }

                    }
                    else {
                    //if (this.state.authy !== contentFsm.SIGNED_OUT) {

                        console.log('err: ', err);
                        this.setState({ authy: contentFsm.SIGNED_OUT });
                        EpdSmartApiLoad(this.state, this.setState, "unload"); // UNLOAD
                    }*/

            });
        //console.log('set Auth complete');
    }

    handleSelect(key) {
        //alert(key);

        this.lastLocation = this.props.history.location;
        console.log('NAV keyevent', key)
        switch (key) {
        //    case '/signout-modal':
         //       this.path = key;
         //       this.setState({refresh: this.state.refresh+1})   // refresh request      
         //       break;
            case '/home': 
                        if (this.state.authy === contentFsm.SIGNED_IN) { 
                            //EpdSmartApiLoad(this.state, this.setState, "unload"); // UNLOAD
                            EpdSmartApiLoad(this.state, this.setState, "maximum"); 
                        } 
                        this.props.history.push(key);
                        break;

            case '/refresh': 
                        if (this.state.authy === contentFsm.SIGNED_IN) { 
                            EpdSmartApiLoad(this.state, this.setState, "minimum"); 
                        } 
                        break;
                    
            default: {
                        if (this.state.authy === contentFsm.SIGNED_IN) {
                            EpdSmartApiLoad(this.state, this.setState, "maximum");
                        }
                        this.props.history.push(key)
            }
        }
        
    }

    render() {

     


        console.log('Content', this.state, this.props.history.location, this.lastLocation)

        if (!this.state.mounted) return null;
        //Auth.currentAuthenticatedUser().then(user => console.log('user: ', user)).catch(err => console.log('err: ', err));
        //this.setauthstate();
        //console.log('AuthState=', this.state.authy);
        // Auth.currentCredentials().then(creds => console.log('creds: ', creds));

           

            const textCenter = {
                textAlign: "center"
            };

            const textRight = {
                textAlign: "right"
            };

            const rowStyle = {
                // height: '100vw',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
               // marginBottom: '1rem'
                padding: '1rem'
            };


            switch (this.state.authy) {
                case contentFsm.UNDEFINED:
                    this.setauthstate('render');
                    return (null);


                    //break;
/*
                case contentFsm.REQUEST_SIGN_IN:
                    console.log('REQUEST_SIGN_IN');
                    return (           
                          <Container fluid="lg">

                                <Row style={rowStyle}>
                                    <Col>
                                        <Authentication/>
                                    </Col>
                                </Row>
                             
                           </Container>
                    );
*/

/*
                                <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                                <Navbar.Collapse id="reponsive-navbar-nav">
                                    <Nav.Link eventKey="/home">Home</Nav.Link>
                                      </Navbar.Collapse>     
*/
                case contentFsm.REQUEST_SIGN_IN:
                    console.log('REQUEST_SIGN_IN');
                    return (           


                            <Container fluid="lg">
                             <Navbar collapseOnSelect bg="light" expand="lg" sticky="top" className="menu_row" >
                                <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                                    <Navbar.Collapse id="reponsive-navbar-nav">
                                        <Nav className="mr-auto" onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                                            <Nav.Link eventKey="/cancel">Home</Nav.Link>
                                        </Nav>
                                    </Navbar.Collapse>

                                    <Nav className="mr" onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                                        <Nav.Link eventKey="/cancel"><Button variant="secondary">Cancel</Button></Nav.Link> 
                                        {/*<Nav.Link eventKey="/signin"> <Button variant="primary" onClick={this.signin}>Sign In</Button></Nav.Link> */}
                                    </Nav>
                            </Navbar>

                            <Row style={rowStyle}>
                                <Col>
                                    <Switch>
                                        <Route path="/cancel"><Action action={this.cancel}/></Route>    
                                        <Route><Authentication/></Route>                                 
                                    </Switch>
                                </Col>
                            </Row>
                        </Container>
                    );
   

                case contentFsm.REQUEST_SIGN_OUT:
                    Auth.signOut()
                        .catch(
                            err => {
                                console.log('err: ', err);

                            });
                   // this.setState({ authy: contentFsm.SIGNED_OUT });
                    return (null);

                case contentFsm.SIGNED_OUT:
                    return (
                        <Container fluid="lg">
                             <Navbar collapseOnSelect bg="light" expand="lg" sticky="top" className="menu_row" >
                                <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                                    <Navbar.Collapse id="reponsive-navbar-nav">
                                        <Nav className="mr-auto" onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                                            <Nav.Link eventKey="/home">Home</Nav.Link>
                                        </Nav>
                                    </Navbar.Collapse>
                                    <Nav className="mr" onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                                        <Nav.Link eventKey="/signin"><Button variant="primary">Sign In</Button></Nav.Link> 
                                        {/*<Nav.Link eventKey="/signin"> <Button variant="primary" onClick={this.signin}>Sign In</Button></Nav.Link> */}
                                    </Nav>
                            </Navbar>
      
                            <Row style={rowStyle}>
                                <Col>
                                    <Switch>
                                        <Route path="/signin"><Action action={this.signin}/></Route>
                                        <Route><Home state={this.state}/></Route>   
                                    </Switch>
                                </Col>
                            </Row>
                       
                        </Container>
                    );
                        

                case contentFsm.SIGNED_IN:
                    return (
                        <Container fluid="lg">
                            <Navbar collapseOnSelect bg="light" expand="lg" sticky="top" className="menu_row" >
                                <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="order-md-0 order-0" />
                                <Navbar.Collapse id="reponsive-navbar-nav">
                                    <Nav className="mr-auto" onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                                        <Nav.Link eventKey="/home">Home</Nav.Link>
                                        <Nav.Link eventKey="/summary">Summary</Nav.Link>
                                        <Nav.Link eventKey="/sites">Sites</Nav.Link>
                                        <Nav.Link eventKey="/status">Backups</Nav.Link>
                                        <Nav.Link eventKey="/uptime">Uptime </Nav.Link>
                                        <Nav.Link eventKey="/hosting">Hosting </Nav.Link>
                                        <Nav.Link eventKey="/information">Information</Nav.Link>
                                        <Nav.Link eventKey="/registrar">Registrar</Nav.Link>
                                        <Nav.Link eventKey="/analytics">Analytics</Nav.Link>
                                        <Nav.Link eventKey="/details">Details</Nav.Link>
                                        {/*<Nav.Link eventKey="/signoutrequest">Signout Request</Nav.Link>*/}
                                    </Nav>
                                </Navbar.Collapse>
                                <Nav className="mr" onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                                    <ButtonToolbar>
                                    <Nav.Link eventKey="/refresh"><Refresh state={this.state} /></Nav.Link> 
                                    <Nav.Link eventKey="/signoutrequest"><Button variant="secondary">Sign Out</Button></Nav.Link> 
                                    </ButtonToolbar>
                                </Nav>
                            </Navbar>

                           
                            <Row style={rowStyle}>
                                <Col>
                                <Switch>
                                    <Route path="/summary"><Epdsummary state={this.state}/></Route>
                                    <Route path="/sites"><Epdsites state={this.state}/></Route>
                                    <Route path="/status"><Epdbackupstatus state={this.state}/></Route>
                                    <Route path="/analytics"><Epdanalytics state={this.state}/></Route>
                                    <Route path="/hosting"><Epdhosting state={this.state}/></Route>
                                    <Route path="/information"><Epdinformation state={this.state}/></Route>
                                    <Route path="/registrar"><Epdregistrar state={this.state}/></Route>
                                    <Route path="/uptime"><Epduptime state={this.state}/></Route>
                                    <Route path="/details"><Epddetails state={this.state}/></Route>
                                    <Route path="/signout"><Action action={this.signout}/></Route>
                                    <Route path="/signoutrequest"><NavConfirm state={this.state} linkButton="true" buttonVariant="secondary" linkText="Sign Out" heading="Confirm Sign Out" description="Click Yes to confirm" pathYes="/signout" pathNo={this.lastLocation.pathname}  history={this.props.history} /></Route>
                                    <Route><Home state={this.state}/></Route>
                                </Switch>    
                                </Col>
                            </Row>
                            
                        </Container>
                    );


                default:
                    return (<div><p>Unknown State {this.state.authy}</p></div>);
            
        } 
    }
}




export default withRouter(Content);
/*
{ (this.props.auth && (pathModal == "/signout-modal")) && <NavConfirm linkButton="true" buttonVariant="secondary" linkText="Sign Out" heading="Confirm Sign Out" description="Click Yes to confirm" pathYes="/signout" pathNo={this.lastLocation.pathname} history={this.props.history}/>}

pathNo={this.lastLocation.pathname} history={this.props.history}

pathNo="/home" history={this.props.history}

*/